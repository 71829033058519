<template>
  <v-dialog
    v-model="dialogAdd"
    @click:outside="$emit('update:dialogAdd', false)"
    @keydown.esc="$emit('update:dialogAdd', false)"
    max-width="1300"
    scrollable
    fullscreen
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="pa-4 font-weight-bold accent">
        Adicionar a Equipe
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAdd', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="py-8">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <!-- FOTO -->
            <v-col cols="12" md="3">
              <!-- <h1 class="text-h6 font-weight-bold mb-4">Avatar</h1> -->
              <v-card
                flat
                :outlined="avatar ? true : false"
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="avatar">
                  <v-img :src="avatar" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%;">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="secondary">
                        mdi-image-plus
                      </v-icon>

                      <h3 class="text-subtitle-1 ">
                        Arrastar e soltar
                      </h3>
                      <div class="text-caption">
                        Clique para selecionar
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>

            <!-- Dados -->
            <v-col cols="12" md="9" class="px-9">
              <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>

              <v-row>
                <!--Nome  -->
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="equipe_individuo.nome"
                    label="Nome"
                    dense
                    required
                    :rules="[formRules]"
                  ></v-text-field>
                </v-col>

                <!-- Responsavel -->
                <v-col cols="12" md="4">
                  <v-text-field dense readonly value="Responsavel:">
                    <template v-slot:append>
                      <v-switch
                        v-model="equipe_individuo.responsavel"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <!-- CPF -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-mask="`###.###.###-##`"
                    v-model="equipe_individuo.cpf"
                    label="CPF"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- RG -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="equipe_individuo.rg"
                    label="RG"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- ANIVESARIO -->
                <v-col cols="12" md="4">
                  <DataField
                    label="Data Aniversário"
                    :data_sync.sync="equipe_individuo.data_aniversario"
                  />
                </v-col>

                <!--E-mail  -->
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="equipe_individuo.email"
                    label="E-mail Pessoal"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Cargo Selected -->
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="selectedCargo"
                    :items="cargos"
                    label="Cargo"
                    item-text="descricao"
                    item-value="id"
                    dense
                    required
                    :rules="[formRules]"
                  ></v-combobox>
                </v-col>

                <!-- telefone -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.telefone"
                    v-mask="`(##) ####-####`"
                    label="Telefone"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- celular -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.celular"
                    v-mask="`(##) #.####-####`"
                    label="Celular"
                    dense
                    required
                    :rules="[formRules]"
                  ></v-text-field>
                </v-col>

                <!-- whatsapp -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.whatsapp"
                    v-mask="`(##) #.####-####`"
                    label="Whatsapp"
                    dense
                  ></v-text-field>
                </v-col>

                <!--Telegram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.telegram"
                    v-mask="`(##) #.####-####`"
                    label="Telegram"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Endereco -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
              <v-row>
                <!-- CEP -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cep"
                    v-mask="`#####-###`"
                    label="CEP"
                    dense
                    append-icon="mdi-magnify"
                    @keyup.enter="fetchCep"
                    @click:append="fetchCep"
                    :loading="loadingCep"
                  ></v-text-field>
                </v-col>

                <!--endereco -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Endereço"
                    v-model="equipe_individuo.endereco"
                    dense
                    readonly
                  >
                  </v-text-field>
                </v-col>

                <!--('numero')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Número"
                    v-model="equipe_individuo.numero"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('('bairro')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Bairro"
                    v-model="equipe_individuo.bairro"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('('complemento')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Complemento"
                    v-model="equipe_individuo.complemento"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Cidade -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Cidade"
                    v-model="equipe_individuo.cidade"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- UF -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="equipe_individuo.uf"
                    label="UF"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!--Contatos -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
              <v-row>
                <!-- Instagram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Instagram"
                    v-model="equipe_individuo.instagram"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Facebook -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Facebook"
                    v-model="equipe_individuo.facebook"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Linkedin -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Linkedin"
                    v-model="equipe_individuo.linkedin"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Youtube -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Youtube"
                    v-model="equipe_individuo.youtube"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn @click="$emit('update:dialogAdd', false)">
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="createEquipe()"
          color="secondary"
          class="white--text"
          :disabled="!validForm"
        >
          Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { uploadAvatar, postEquipe } from "@/api/gestor/cliente_equipe.js";
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { fetchCargos } from "@/api/gestor/cargos.js";
import { buscaCep } from "@/api/geral/buscaCep.js";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "AddEquipe",

  props: {
    dialogAdd: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    vueDropzone: vue2Dropzone,
    DataField: () =>
      import("../../../../../../components/fields/DataField.vue"),
  },

  data() {
    return {
      equipe_individuo: {
        avatar: "",
        nome: "",
        responsavel: false,
        cpf: "",
        rg: "",
        status: 1,
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cep: "",
        uf: "",
        cidade: "",
        email: "",
        telefone: "",
        celular: "",
        whatsapp: "",
        telegram: "",
        facebook: "",
        linkedin: "",
        youtube: "",
        data_aniversario: "",
      },
      loading: false,
      avatar: "",
      validForm: true,
      formRules: inputRequired,
      emailRules: validaEmail,
      hoverFile: false,
      avatarUpload: null,
      selectedCargo: null,
      cargos: [],
      loadingCargos: false,
      loadingCep: false,
      cep: "",
    };
  },

  computed: {
    cliente_id() {
      return this.$route.params.clienteId;
    },
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
  },

  watch: {
    selectedCargo() {
      if (this.selectedCargo) {
        this.equipe_individuo.cargo_id = this.selectedCargo.id;
      }
    },
  },

  methods: {
    fetchCep() {
      if (this.cep && this.cep.length === 9) {
        this.equipe_individuo.cep = "";
        this.equipe_individuo.cidade = "";
        this.equipe_individuo.bairro = "";
        this.equipe_individuo.uf = "";
        this.equipe_individuo.endereco = "";

        this.loadingCep = true;
        buscaCep(this.cep)
          .then((response) => {
            this.equipe_individuo.cep = response.cep;
            this.equipe_individuo.cidade = response.city;
            this.equipe_individuo.bairro = response.neighborhood;
            this.equipe_individuo.uf = response.state;
            this.equipe_individuo.endereco = response.street;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("CEP não encontrado!");
            }
          })
          .finally(() => {
            this.loadingCep = false;
          });
      }
    },

    getCargos() {
      this.loadingCargos = true;
      fetchCargos()
        .then((response) => {
          this.cargos = response;
          this.loadingCargos = false;
        })
        .catch(() => {
          this.loadingCargos = false;
        });
    },

    createEquipe() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const equipe_individuo = new FormData();
        if (this.avatarUpload) {
          equipe_individuo.append(
            "avatar",
            this.avatarUpload,
            this.avatarUpload.name
          );
        }
        equipe_individuo.append("cliente_id", this.cliente_id);
        equipe_individuo.append("nome", this.equipe_individuo.nome);
        equipe_individuo.append("status", this.equipe_individuo.status);
        equipe_individuo.append(
          "responsavel",
          this.equipe_individuo.responsavel
        );
        equipe_individuo.append("cpf", this.equipe_individuo.cpf);
        equipe_individuo.append("rg", this.equipe_individuo.rg);
        equipe_individuo.append("endereco", this.equipe_individuo.endereco);
        equipe_individuo.append("numero", this.equipe_individuo.numero);
        equipe_individuo.append("bairro", this.equipe_individuo.bairro);
        equipe_individuo.append(
          "complemento",
          this.equipe_individuo.complemento
        );
        equipe_individuo.append("cep", this.equipe_individuo.cep);
        equipe_individuo.append("uf", this.equipe_individuo.uf);
        equipe_individuo.append("cidade", this.equipe_individuo.cidade);
        equipe_individuo.append("cargo_id", this.equipe_individuo.cargo_id);
        equipe_individuo.append("email", this.equipe_individuo.email);
        equipe_individuo.append("telefone", this.equipe_individuo.telefone);
        equipe_individuo.append("celular", this.equipe_individuo.celular);
        equipe_individuo.append("whatsapp", this.equipe_individuo.whatsapp);
        equipe_individuo.append("telegram", this.equipe_individuo.telegram);
        equipe_individuo.append("facebook", this.equipe_individuo.facebook);
        equipe_individuo.append("linkedin", this.equipe_individuo.linkedin);
        equipe_individuo.append("youtube", this.equipe_individuo.youtube);
        if (this.equipe_individuo.data_aniversario !== "") {
          equipe_individuo.append(
            "data_aniversario",
            this.equipe_individuo.data_aniversario
          );
        }

        postEquipe(equipe_individuo)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Adicionado com sucesso!");
              this.$emit("fetch-equipe");
              this.$emit("update:dialogAdd", false);
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    fileAdded(file) {
      this.avatarUpload = file;

      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
      };
    },

    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];

      const fd = new FormData();
      fd.append("avatar", file, file.name);

      uploadAvatar(this.equipe.id, fd)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Avatar atualizada com sucesso!");
            this.getEquipe();
          }
        })
        .catch(() => {});
    },
  },

  mounted() {
    this.getCargos();
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}

#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
